import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const getRequest = (url, params) => instance.get(url, params);
export const postRequest = (url, data) => instance.post(url, data);
export const putRequest = (url, data) => instance.put(url, data);
export const patchRequest = (url, data) => instance.patch(url, data);
export const postLogout = (url) => instance.post(url);
export const deleteRequest = (url) => instance.post(url);
export const deleteReq = (url) => instance.delete(url);

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Check if token cookie is set
    const tokenCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));

    // Use token from cookie or localStorage
    const token = tokenCookie?.split("=")[1] || localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
