import React, { memo } from 'react';
import './LoadingOverlay.css'; // Import the external CSS file

const LoadingOverlay = () => {
    return (
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
    );
};

export default memo(LoadingOverlay);
