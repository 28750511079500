import React, { memo, useCallback, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { toast } from 'sonner';
import ButtonAnimated from '../../components/comman/Button/AnimatedButton/ButtonAnimated';
import LabelComponent from '../../components/comman/Label/LabelComponent';
import { capitalizeWords } from '../../extraStuff';
import { getRequest } from '../../service/ApiEndpoint';

function CreateMemberDate() {
    const [itsId, setItsId] = useState('');
    const [user, setUser] = useState([]);

    const fetchUsersWithDate = useCallback(async (e) => {
        e.preventDefault();

        const isValidITSNumber = () => {
            return /^\d{8}$/.test(itsId);
        };

        if (!itsId) return toast.error("Enter ITS Number");
        if (!isValidITSNumber()) {
            toast.error("ITS Number must be exactly 8 digits.");
            return;
        }

        if (!itsId) return false;

        try {
            const request = await getRequest(`/user/search-member-date/${itsId}`, {});
            const response = request?.data;

            // Ensure response is an array before setting data
            if (response?.data) {
                setUser(response?.user);
            } else {
                setUser([]); // Set empty array if response is not valid
            }
        } catch (error) {
            if (error.status === 404) {
                toast.error(error?.response?.data?.message);
            }
            setUser([]); // Set empty array on error
        }
        setItsId('');
    }, [itsId]);

    const handleCreateDates = async () => {
        // if (data.length >= 0) return;

        console.log('running  ww');
        try {
            const request = await getRequest(`/user/create-member-date/${user?.itsId}`, {});
            const response = request?.data;

            // Ensure response is an array before setting data
            if (response) {
                toast.success('All dates created successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            if (error.status === 404) {
                toast.error(error?.response?.data?.message);
            }
        }
    }

    return (
        <div style={{ minHeight: '50vh' }} className='px-4 mb-6'>
            <div className='max-w-screen-xl mt-14 bg-white py-2 rounded-xl shadow-xl mx-auto overflow-auto min-h-[400px]'>
                <div className='flex justify-between items-center gap-2 '>
                    <LabelComponent labelNameClass={'lg:text-4xl sm:text-3xl text-xl font-extrabold text-pri mx-4'} labelName={'Create Member Dates'} />
                </div>

                <div className='motif-central'>&nbsp;</div>
                <div className='flex flex-col flex-wrap items-start gap-0 max-w-auto px-2'>
                    <label htmlFor='member_its_number' className='text-sm base-text labelFontStyle block'>Enter Member ITS Number</label>
                    <div className='flex flex-row flex-wrap gap-2 items-center'>
                        <input type="text" id="member_its_number" value={itsId}
                            className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-auto px-4 py-2 font-normal"
                            placeholder='eg: 12345678'
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value.length <= 8) { setItsId(e.target.value) }
                            }} />
                        <ButtonAnimated btnIcon={<IoPersonAddSharp className='text-xl' />} btnClassName={'h-full'} btnOnStyle={{ lineHeight: '30px', padding: '3px 5px' }} btnName={'Submit'}
                            btnOnClick={fetchUsersWithDate} />
                    </div>
                </div>

                {user && user.length !== 0 &&
                    <div className='border-2 border-sec m-2 p-2 rounded-md mt-4'>
                        <div className="grid gap-4 mb-0 grid-cols-2 sm:px-4 px-1">
                            {/* FIRST NAME */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Name
                                </p>
                                <p name="first_name" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {capitalizeWords(user?.first_name)} {capitalizeWords(user?.last_name)}</p>
                            </div>
                            {/* Email */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Email
                                </p>
                                <p name="email" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-2 py-1 font-normal">
                                    {capitalizeWords(user?.email)}</p>
                            </div>

                            {/* Size */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    ITS Number
                                </p>
                                <p name="size" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {user?.itsId}</p>
                            </div>
                            {/* Size */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Thaali Size
                                </p>
                                <p name="size" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {capitalizeWords(user?.size)}</p>
                            </div>
                        </div>
                    </div>
                }

                {/* Delete Entries */}
                {user && user.length !== 0 &&
                    <ButtonAnimated
                        btnClassName={'h-full'}
                        btnOnStyle={{ lineHeight: '30px', padding: '3px', border: '2px solid #43a047', borderRadius: '0.5rem', boxShadow: '#43a047 4px 4px 0 0', color: '#43a047' }}
                        btnName={'Push Dates'}
                        btnOnClick={handleCreateDates} />}
            </div>
        </div >
    )
}
export default memo(CreateMemberDate);