import { getRequest } from "./service/ApiEndpoint";

export async function getUser() {
  try {
    const request = await getRequest("/user/token");
    return request?.data;
    // Catch Error
  } catch (error) {
    // console.log(error);
  }
}
