import { configureStore } from "@reduxjs/toolkit";
import AuthSliceReducer from "../slices/authSlice2";
import eventsReducer from "../slices/eventsSlice";
// import storage from "redux-persist/lib/storage";
// import sessionStorage from 'redux-persist/lib/storage/session';
// import {
//  persistReducer,
// persistStore,
// } from "redux-persist";

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["user"],
// };

// const persistRed = persistReducer(persistConfig, AuthSliceReducer);

const store = configureStore({
  reducer: {
    // Auth: persistRed,
    Auth: AuthSliceReducer,
    calendarEvents: eventsReducer, // for eventsSlice
  },
});

// export const persistor = persistStore(store);
export default store; // Ensure store is exported by default
