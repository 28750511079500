// SidebarData.js
import { Settings } from "@mui/icons-material";
import React from "react";
import { BiCalendarEdit } from "react-icons/bi";
import { FaUserCircle, FaUsers } from "react-icons/fa";
import {
  GrMapLocation,
  GrOverview,
  GrUserSettings,
  GrValidate,
} from "react-icons/gr";
import { HiHome } from "react-icons/hi2";
import { MdDashboard, MdOutlineMenuBook } from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import { TbMessageCircle } from "react-icons/tb";

// Custom hook for handling logout
// const useLogout = () => {
//   const dispatch = useDispatch();

//   const handleLogout = async () => {
//     const res = await postLogout("/auth/logout", {});
//     dispatch(setLogout());
//     if (res.status === 201) {
//       setTimeout(() => {
//         window.location.href = "/";
//         toast.success("Logout Successful");
//       }, 500);
//     }
//   };

//   return handleLogout;
// };

// Component for logout button
// const LogoutButton = () => {
//   const handleLogout = useLogout();

//   return (
//     <div
//       onClick={handleLogout}
//       className="flex items-center justify-center gap-4"
//     >
//       <MdOutlineLogout />
//       <span>Logout</span>
//     </div>
//   );
// };

// const ContactUs = () => {
//   return <img src={contactUs} alt="Contact-Us" width={24} height={24} />;
// };

// Sidebar data with LogoutButton component
export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <HiHome />,
    access: ["public", "user", "coordinator", "admin"],
  },

  // Admin-only routes
  {
    title: "Dashboard",
    path: "/admin",
    icon: <MdDashboard />,
    access: ["admin"],
  },
  {
    title: "Menu",
    path: "/admin/menu_master",
    icon: <MdOutlineMenuBook />,
    access: ["admin"],
  },
  {
    title: "Overview",
    icon: <GrOverview />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    access: ["admin"],
    subNav: [
      {
        title: "Area",
        path: "/admin/area_master",
        icon: <GrMapLocation />,
        access: ["admin"],
      },
      {
        title: "Coordinator",
        path: "/admin/coordinator_master",
        icon: <GrUserSettings />,
        access: ["admin"],
      },
      {
        title: "Members",
        path: "/admin/member_master",
        icon: <FaUsers />,
        access: ["admin"],
      },
    ],
  },
  {
    title: "Settings",
    icon: <Settings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    access: ["admin"],
    subNav: [
      {
        title: "Manage Dates",
        path: "/admin/search-member-dates",
        icon: <BiCalendarEdit />,
        access: ["admin"],
      },
      {
        title: "Create Dates",
        path: "/admin/create-member-dates",
        icon: <GrValidate />,
        access: ["admin"],
      },
    ],
  },
  // {
  //   title: "History",
  //   path: "/logs",
  //   icon: <FaHistory />,
  //   access: ["public", "user", "coordinator", "admin"],
  // },

  // Coordinator-only routes
  {
    title: "Dashboard",
    path: "/coordinator",
    icon: <MdDashboard />,
    access: ["coordinator"],
  },
  {
    title: "Members",
    path: "/coordinator/member",
    icon: <FaUsers />,
    access: ["coordinator"],
  },
  {
    title: "Profile",
    path: "/coordinator/profile",
    icon: <FaUserCircle />,
    access: ["coordinator"],
  },

  // User-only routes
  {
    title: "Dashboard",
    path: "/member",
    icon: <MdDashboard />,
    access: ["user"],
  },
  {
    title: "Default Days",
    path: "/member/days",
    icon: <FaUsers />,
    access: ["user"],
  },
  {
    title: "Profile",
    path: "/member/profile",
    icon: <FaUserCircle />,
    access: ["user"],
  },

  // Public routes
  {
    title: "Contact Us",
    path: "https://www.aesleicester.co.uk/contact/",
    icon: <TbMessageCircle />,
    access: ["public", "user", "coordinator", "admin"],
  },
  // {
  //   title: "Login",
  //   path: "/login",
  //   icon: <MdOutlineLogin />,
  //   access: ["public"], // Only visible when user is not logged in
  // },
  // {
  //   title: "",
  //   icon: <LogoutButton />, // Uses LogoutButton component
  //   access: ["user", "coordinator", "admin"], // Only visible when user is logged in
  // },
];
