import { Button, Dialog, DialogFooter } from '@material-tailwind/react';
import React, { memo, useCallback, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'sonner';
import ButtonAnimated from '../../components/comman/Button/AnimatedButton/ButtonAnimated';
import LabelComponent from '../../components/comman/Label/LabelComponent';
import { capitalizeWords, formatDateToReadable, formatTimeWithAmPm, getDayName } from '../../extraStuff';
import { deleteReq, getRequest } from '../../service/ApiEndpoint';

function SearchMemberDate() {
    const [itsId, setItsId] = useState('');
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);

    // Confirmation Modal State
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    // Custom Table
    const headers = [
        {
            id: 1,
            KEY: "",
            LABEL: "S. No.",
        },
        {
            id: 2,
            KEY: "date",
            LABEL: "Date",
        },
        {
            id: 3,
            KEY: "",
            LABEL: "Day Name",
        },
        {
            id: 4,
            KEY: "size",
            LABEL: "Thaali Size",
        },
        {
            id: 5,
            KEY: "updatedAt",
            LABEL: "Last Edited",
        }
    ];

    const fetchUsersWithDate = useCallback(async (e) => {
        e.preventDefault();

        const isValidITSNumber = () => {
            return /^\d{8}$/.test(itsId);
        };

        if (!itsId) return toast.error("Enter ITS Number");
        if (!isValidITSNumber()) {
            toast.error("ITS Number must be exactly 8 digits.");
            return;
        }

        if (!itsId) return false;

        try {
            const request = await getRequest(`/user/search-member-date/${itsId}`, {});
            const response = request?.data;

            // Ensure response is an array before setting data
            if (response?.data) {
                setData(response?.data);
                setUser(response?.user);
            } else {
                setData([]); // Set empty array if response is not valid
                setUser([]); // Set empty array if response is not valid
            }
        } catch (error) {
            if (error.status === 404) {
                toast.error(error?.response?.data?.message);
            }
            setData([]); // Set empty array on error
            setUser([]); // Set empty array on error
        }
        setItsId('');
    }, [itsId]);

    const handleDeleteAll = async () => {
        if (data.length === 0) return;

        try {
            const request = await deleteReq(`/user/search-member-date/${Number(data[0]?.userId)}`, {});
            const response = request?.data;

            // Ensure response is an array before setting data
            if (response) {
                setIsConfirmOpen(false)
                toast.success('All dates deleted successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                setData([]);
            }
        } catch (error) {
            if (error.status === 404) {
                toast.error(error?.response?.data?.message);
            }
        }

    }

    return (
        <div style={{ minHeight: '50vh' }} className='px-4 mb-6'>
            <div className='max-w-screen-xl mt-14 bg-white py-2 rounded-xl shadow-xl mx-auto overflow-auto min-h-[400px]'>
                <div className='flex justify-between items-center gap-2 '>
                    <LabelComponent labelNameClass={'lg:text-4xl sm:text-3xl text-xl font-extrabold text-pri mx-4'} labelName={'Search Member Dates'} />
                </div>

                <div className='motif-central'>&nbsp;</div>
                <div className='flex flex-col flex-wrap items-start gap-0 max-w-auto px-2'>
                    <label htmlFor='member_its_number' className='text-sm base-text labelFontStyle block'>Enter Member ITS Number</label>
                    <div className='flex flex-row flex-wrap gap-2 items-center'>
                        <input type="text" id="member_its_number" value={itsId}
                            className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-auto px-4 py-2 font-normal"
                            placeholder='eg: 12345678'
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value.length <= 8) { setItsId(e.target.value) }
                            }} />
                        <ButtonAnimated btnIcon={<IoPersonAddSharp className='text-xl' />} btnClassName={'h-full'} btnOnStyle={{ lineHeight: '30px', padding: '3px 5px' }} btnName={'Submit'}
                            btnOnClick={fetchUsersWithDate} />
                    </div>
                </div>

                {user && user.length !== 0 &&
                    <div className='border-2 border-sec m-2 p-2 rounded-md mt-4'>
                        <div className="grid gap-4 mb-0 grid-cols-2 sm:px-4 px-1">
                            {/* FIRST NAME */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    First Name
                                </p>
                                <p name="first_name" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {capitalizeWords(user?.first_name)}</p>
                            </div>
                            {/* LAST NAME */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Last Name
                                </p>
                                <p name="last_name" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {capitalizeWords(user?.last_name)}</p>
                            </div>
                            {/* Email */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Email
                                </p>
                                <p name="email" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-2 py-1 font-normal">
                                    {capitalizeWords(user?.email)}</p>
                            </div>

                            {/* CONTACT */}
                            {/* Email */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Contact
                                </p>
                                <p name="phone" className="min-h-[30px] border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {user?.phone}</p>
                            </div>

                            {/* IST NUMBER */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    ITS Number
                                </p>
                                <p name="itsId" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {user?.itsId}</p>
                            </div>

                            {/* Size */}
                            <div className="col-span-2 sm:col-span-1 flex items-start justify-between gap-2">
                                <p className="block antialiased font-sans text-sm leading-normal mb-0.5 text-left font-medium text-bluegray min-w-fit">
                                    Thaali Size
                                </p>
                                <p name="size" className="border border-blue-gray-200 text-blue-gray-400 text-sm rounded-md focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-1 font-normal">
                                    {capitalizeWords(user?.size)}</p>
                            </div>
                        </div>
                    </div>
                }

                {data && data.length > 0 ?
                    <div className="relative overflow-x-auto shadow-md rounded-lg mt-6 mb-4 border-2 border-sec mx-2 bg-white">
                        {/* TABLE */}
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400" >
                            {/* TABLE HEAD */}
                            <thead className="text-xs uppercase bg-sec text-sec">
                                <tr>
                                    {headers?.map((header, index) => (
                                        <th className="min-w-max pl-2 px-1 py-3 font-semibold" key={index} >
                                            <div className='flex justify-start items-center'>
                                                <span>{header.LABEL}</span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            {/* TABLE BODY */}
                            <tbody>
                                {data?.map((el, idx) =>
                                    <tr key={el?.id} id={el?.id} className='hover:bg-[--table-bg-hoverBlue]'>
                                        <td className="px-2 py-1 font-medium base-text">
                                            {idx + 1}
                                        </td>
                                        <td className="px-2 py-1 base-text-secondary font-bold tracking-widest">
                                            {el?.date.slice(0, 10)}
                                        </td>
                                        <td className="px-2 py-1 base-text font-medium">
                                            {getDayName(el?.date.slice(0, 10))}
                                        </td>
                                        <td className="px-2 py-1 base-text font-medium">
                                            {capitalizeWords(el?.size)}
                                        </td>
                                        <td className="px-2 py-1 base-text opacity-75 text-gray-600 min-w-[200px]">
                                            {formatDateToReadable(el?.updatedAt.slice(0, 10))}
                                            &nbsp;
                                            {formatTimeWithAmPm(el?.updatedAt.slice(11))}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    :
                    <p className='mx-2 my-4 text-xl text-red-600'>No Data Found</p>
                }

                {/* Delete Entries */}
                {data && data.length > 0 &&
                    <ButtonAnimated
                        btnIcon={<MdDeleteForever className='text-xl text-red-600' />}
                        btnClassName={'h-full'}
                        btnOnStyle={{ lineHeight: '30px', padding: '3px', border: '2px solid #e53935', borderRadius: '0.5rem', boxShadow: '#e53935 4px 4px 0 0', color: '#e53935' }}
                        btnName={'Delete All'}
                        btnOnClick={() => setIsConfirmOpen(!isConfirmOpen)} />
                }

                {/* Confirmation Dailog Modal */}
                <Dialog open={isConfirmOpen} handler={() => setIsConfirmOpen(!isConfirmOpen)}>
                    <h1 className='font-black text-gray-800 text-xl m-4'>Confirm Deletion</h1>
                    <p className='font-normal text-gray-600 text-md m-4'>
                        Are you sure you want to delete displayed user dates?
                        <br />
                        <span className='text-sm text-gray-600 font-semibold'>Note :</span><span className='text-sm text-red-600'> This action is irreversible.
                            <br />
                            <span className='text-xs'>If you proceed, the member will no longer be able to update the Thaali status for the displayed dates.</span>
                        </span>
                    </p>
                    <DialogFooter className='flex gap-2'>
                        <Button variant="text" color="red" onClick={() => setIsConfirmOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="gradient" color="green" onClick={handleDeleteAll}>
                            Confirm
                        </Button>
                    </DialogFooter>
                </Dialog>
            </div>
        </div >
    )
}
export default memo(SearchMemberDate);