import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { HiMenuAlt1 } from "react-icons/hi";
import { IconContext } from "react-icons/lib";
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import styled from "styled-components";
import { setLogout, setUser } from "../app/slices/authSlice2";
import Bismillah from "../assets/images/bismillah.png";
import FMBLogopreview from "../assets/images/fmb_logo.png";
import { capitalizeWords } from "../extraStuff";
import { getUser } from "../getUser";
import { postLogout } from "../service/ApiEndpoint";
import "./Sidebar.css";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

const Nav = styled.div`
  background: #15171c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  min-height: 80px;
  font-size: 1.5rem;
  margin: 0 10px 10px 10px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  padding: 0 10px;
  // background: #a9bb70;
`;

const SidebarNav = styled.nav`
  // background: #15171c;
  width: 250px;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  // left: ${({ $sidebar }) => ($sidebar ? "0" : "-100%")}; // Use sidebar here
  transition: all 350ms ease-in-out;
  z-index: 10;
  // padding-bottom: 81px;
`;

const SidebarWrap = styled.div`
  width: 100%;
  height: calc(100% - 240px);
  overflow-y: auto;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userLoggedIn = useSelector((state) => state.Auth.user);
  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    async function fetchUser() {
      if (!userLoggedIn) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        userLoggedIn = await getUser()
        if (userLoggedIn) {
          dispatch(setUser(userLoggedIn))
        }
      }
    }
    fetchUser();
  }, [dispatch, navigate]);

  const handleLogout = async () => {
    const res = await postLogout("/auth/logout", {});
    dispatch(setLogout());
    if (res.status === 201) {
      // window.location.href = "/";
      navigate("/login");
      Cookies.remove("token");
      localStorage.removeItem("token");
      setTimeout(() => {
        toast.success("Logout Successful");
      }, 500);
    }
    showSidebar();
  };

  const handleLogin = () => {
    navigate("/login");
    showSidebar();
  }

  return (
    <IconContext.Provider value={{ color: "#fff", overflow: "hidden !important" }}>
      <Nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          zIndex: "30",
          position: "sticky",
          top: "0",
        }}
        className="bismillah_img specialRatio" >
        <NavIcon to="#" className="mobBlock">
          <HiMenuAlt1 onClick={showSidebar} className="w-10 h-10 scrNone hover:bg-gray-600 hover:rounded-md cursor-pointer p-1" />
        </NavIcon>

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "1rem",
            paddingTop: "1rem",
          }} >
          <img width="200" src={Bismillah} alt="bismillah" />
        </div>
      </Nav>

      <SidebarNav
        $sidebar={sidebar}
        style={{ zIndex: "100" }}
        className={`bg-gradient-to-r from-[var(--bs-primary-rgb2)] to-[var(--bs-primary-rgb)] bg-clip-bg bg-transparent text-white transition-all duration-300 ${sidebar ? 'sidebar sidebarOpen' : 'sidebar sidebarClose'}`} >
        {/* bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent */}

        {/* First Section LOGO*/}
        <div className="bg-white p-0.5 rounded-md shadow-md shadow-gray-900/50 my-2 ml-4 mr-auto" >
          <div className="bg-white border border-[var(--mehndiGolden)] p-1 rounded-md">
            <img src={FMBLogopreview} alt="profileImg" className="w-auto h-12" />
          </div>
        </div>

        {/* Mobile Close Button */}
        <div className="absolute right-5 top-5 border-2 border-white rounded-md cursor-pointer mobBlock hover:bg-gray-600 hover:rounded-md">
          <CgClose onClick={showSidebar} className="w-8 h-8 cursor-pointer" />
        </div>

        {/* Seond Seciton User Overview */}
        <div className="p-4 border-b border-blue-300/50">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-400 to-blue-300 flex items-center justify-center">
                <FaUser size={24} className="text-blue-800" />
              </div>
              <div className={`absolute bottom-0 right-0 w-3 h-3 ${userLoggedIn ? 'bg-green-400' : 'bg-red-400'} rounded-full border-2 border-white`}></div>
            </div>

            <div className="flex flex-col transition-all">
              <span className="font-semibold">{userLoggedIn ? capitalizeWords(userLoggedIn?.first_name) : 'Welcome Back'}</span>
              <span className="text-sm text-blue-200">{userLoggedIn ? capitalizeWords(userLoggedIn?.role === 'user' ? 'Member' : userLoggedIn?.role) : <span onClick={handleLogin}>Login</span>}</span>
            </div>
          </div>
        </div>

        {/* Middle SideNav Links*/}
        <SidebarWrap className="animate__animated animate__bounceInLeft">
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} showSidebar={showSidebar} userLoggedIn={userLoggedIn} />;
          })}
        </SidebarWrap>

        {/* Lower SideNav */}

        <div className={`absolute bottom-0 z-[999] w-[250px] py-4 border-t border-blue-400/30 bg-blue-800 ${sidebar ? 'sidebarOpen' : 'sidebarClose'}`}>
          <div className="flex items-center px-4 py-3 hover:bg-blue-900/30 transition-colors duration-200 cursor-pointer font-medium tracking-widest">
            {userLoggedIn ?
              <>
                <span className="inline-flex items-center justify-center w-8">
                  <MdOutlineLogout size={20} />
                </span>
                <span className="ml-3" onClick={handleLogout}>Logout</span>
              </>
              :
              <span className="inline-flex items-center justify-start w-full ml-1.5" onClick={handleLogin}>
                <span>
                  <MdOutlineLogin size={20} />
                </span>
                <span className="ml-4">Login</span>
              </span>
            }
          </div>
        </div>
      </SidebarNav>
    </IconContext.Provider >
  );
};

export default Sidebar;