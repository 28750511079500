import { format, parseISO } from "date-fns";
import * as XLSX from "xlsx";

export function capitalizeWords(str = "none") {
  // Ensure str is always a string
  let newStr = String(str).split(" ");
  return newStr
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const dayName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// Day Name pass date ('2024-09-30')
export function getDayName(dateString) {
  const date = new Date(dateString);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = daysOfWeek[date?.getDay()];
  return dayName;
}

export function todayLocalDate() {
  // Helper function to get today formated date (optional)
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(today.getDate()).padStart(2, "0");

  const todayDate = year + "-" + month + "-" + day;
  return todayDate;
}

// Helper function to format the date (optional)
export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// Sunday Date
export function getUpcomingWeekDates(inputDate) {
  const today = new Date(inputDate);
  const dayOfWeek = today.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

  // Calculate the start of the upcoming week (Sunday)
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek + 7); // Move to the next Sunday

  const weekDates = [];

  // Loop from Sunday to Saturday to get all the dates
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i); // Add 'i' days to the start date
    weekDates.push(formatDate(date)); // Format the date (optional)
  }
  return weekDates[0];
}

// Monday Date
export function getUpcomingWeekStartDate(inputDate) {
  const date = new Date(inputDate);
  const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

  // Calculate the start of the upcoming week (Sunday)
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - dayOfWeek + 8); // Move to the next Sunday

  return formatDate(startOfWeek);
}

// Current Week All Dates
export function getCurrentWeekDates(inputDate) {
  // Ensure we're working with a date in the local time zone
  const date = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  );
  const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

  // Calculate the start of the current week (Sunday)
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - dayOfWeek);

  const weekDates = [];
  // Loop from Sunday to Saturday to get all the dates
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startOfWeek);
    currentDate.setDate(startOfWeek.getDate() + i);

    // Format the date as 'YYYY-MM-DD' ensuring we use local date
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    weekDates.push(formattedDate);
  }
  return weekDates;
}

export function getPreviousWeekDates(inputDate) {
  const date = new Date(inputDate);
  const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

  // Calculate the start of the previous week (Monday)
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - dayOfWeek - 6); // Move to the previous Monday

  const weekDates = [];

  // Loop from Monday to Sunday to get all the dates of the previous week
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startOfWeek);
    currentDate.setDate(startOfWeek.getDate() + i); // Add 'i' days to the start date
    weekDates.push(currentDate.toISOString().split("T")[0]); // Format the date as 'YYYY-MM-DD'
  }

  return weekDates;
}

export function getUpcomingWeekAllDates(inputDate) {
  if (!inputDate) return;
  const today = new Date(inputDate);
  const dayOfWeek = today.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

  // Calculate the start of the upcoming week (Sunday)
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek + 7); // Move to the next Sunday

  const weekDates = [];

  // Loop from Sunday to Saturday to get all the dates
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i); // Add 'i' days to the start date
    weekDates.push(formatDate(date)); // Format the date (optional)
  }
  return weekDates;
}

export function formatDateToReadable(dateString) {
  const date = parseISO(dateString); // Convert the string to a Date object
  return format(date, "dd MMM, yyyy"); // Format the date to '29 Oct, 2024'
}

export function formatTimeWithAmPm(time) {
  // Split the input time string (HH:MM:SS) into hours, minutes, and seconds
  const [hoursStr, minutesStr, secondsStr] = time.split(":");

  // Convert them to numbers
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Adjust hours for 12-hour format
  const formattedHours = hours % 12 || 12; // Convert 0 hours to 12

  // Format hours, minutes, and seconds to always be two digits
  const paddedHours =
    formattedHours < 10 ? `0${formattedHours}` : formattedHours;
  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds} ${ampm}`;
}

export function calculateWeekPercentageDifference(current, previous) {
  const ans = {};

  for (const key in current) {
    if (current.hasOwnProperty(key) && previous.hasOwnProperty(key)) {
      const currentValue = current[key];
      const previousValue = previous[key];

      if (previousValue === 0) {
        ans[key] = currentValue === 0 ? "0.00%" : "Infinity%";
      } else {
        const difference = currentValue - previousValue;
        const percentageDifference = (difference / previousValue) * 100;
        ans[key] = `${percentageDifference.toFixed(2)}%`;
      }
    }
  }

  return ans;
}

// export function downloadXlsx(fileName, tableRef) {
//   const tableElement = tableRef.current;
//   if (!tableElement) return;

//   // Create a new workbook and add the table data as a worksheet
//   const wb = XLSX.utils.table_to_book(tableElement, { sheet: "report" });

//   // Generate the XLSX file and download it
//   return XLSX.writeFile(wb, `${fileName}.xlsx`);
// }

export function downloadXlsx(fileName, tableRef) {
  const tableElement = tableRef.current;
  if (!tableElement) return;

  // Extract table data manually and preprocess it
  const rows = Array.from(tableElement.querySelectorAll("tr"));
  const sheetData = rows.map((row) => {
    const cells = Array.from(row.querySelectorAll("td, th"));
    return cells.map((cell) => {
      // Check if the cell contains the toggle switch
      const toggleInput = cell.querySelector('input[type="checkbox"]');
      if (toggleInput) {
        // Return "Yes" or "No" based on the toggle's checked status
        return toggleInput.checked ? "Yes" : "No";
      }
      // Otherwise, return the cell's text content
      return cell.innerText || cell.textContent;
    });
  });

  // Create a new workbook and add the preprocessed data as a worksheet
  const ws = XLSX.utils.aoa_to_sheet(sheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "report");

  // Generate the XLSX file and download it
  return XLSX.writeFile(wb, `${fileName}.xlsx`);
}
